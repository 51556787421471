

import React from 'react'
import { Container } from 'react-bootstrap'

export default function Blog() {
    return (
        <Container sx={{ marginTop: 30 }}>
            <h3 variant="h3" color="initial" sx={{ textAlign: 'center', marginY: 7 }}>HTML</h3>
            <hr />
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                1. What are the differences between html4 and html5?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Html5 is the latest version. It has many advanced features. Html5 allows so many new tags like audio,video,canvas and others. Html5 is supported by multimedia.
            </h3>
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                2.What are semantic tags in html? Give me some examples?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Semantic tags clear the meaning to both the developer and the browser.These elements clearly define web page content.
            </h3>

            <h3 sx={{ fontSize: 34, color: 'red' }} >
                3.What is the purpose of Article, div, section, nav, aside?
            </h3 >

            <h3 variant="subtitle1" color="initial">
                Answer: Article : It is used to enclose the main contents of the page. This content could be a news article, forum post, a blog post, or sidebar widgets having a heading.

                Div :It means division tags. It is used in Html to make divisions of content on the web.

                Section: Section tag defines the section of documents such as chapters, headers, footers or any other sections.

                Nav : Navigation likes / menu part of a web page.

                Aside : It represents a portion of a document whose content is only indirectly related to the document’s main content.

            </h3>

            <h3 sx={{ fontSize: 34, color: 'red' }} >
                4.Why will you use Meta tag?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: A type of html tag that provides search engines with information about a website page.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                5. What is the difference between inline, inline-block, and block?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Inline: It doesn’t start on a new line and only occupies just the width it requires. Can’t set the width or height.

                Inline-block: It also doesn’t start on a new line. Can set width and height values.

                Block: It will start on a new line and occupy the full width available. Can set width and height values.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                6. Difference between strong, b, bold, em, i?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer:
                strong: For text important using this tag.
                b:  For text bold using this tag.
                bold: For intended to draw attention to the text using this tag.
                em: For emphasized text using this tag.
                i : For text italic style using this tag.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >

                7. What are properties and attributes in HTML?

            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Properties are the characteristics of an object. Attributes are referring to additional information of an object.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                8. What is a Viewport?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: User's visible area of a web page. It is used in both code and analog design as a way to refer to the display screen and how layout fits into that screen
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                9. Difference between link tag link and anchor tag a?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: The link tag link defines a link between a document and an external resource and these are not clickable. The anchor tag a links are clickable  and it is used to create a hyperlink to another webpage or to a certain part of the webpage.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                10. What is the difference between progress and meter tag?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: The progress tag is used to represent the progress of the task only and the meter tag is used to measure data within a given range.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                11. Define Marquee? How can we apply it?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: In setting a scrolling text on the web page marquee tag help us. Using marquee tags we can apply it.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                12. How are active links different from normal links?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Active links are blue and  normal links are default color. Active links means which link has been clicked at that instant and  normal links are links which are there on the page and have not been clicked yet.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                13. What are two types of Web Storage in HTML5?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer:  There are two storage types of HTML5 are:
                Session Storage: It stores data of the current session only. When the browser closes data is also cleared automatically .
                Local Storage:When the browser closes data is not cleared automatically.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                14. Define an image map?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: By using image map you can link with the different kinds of web pages using a single image. It can be used for defining shapes in the images that are made part of the image mapping process.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                15. How do you insert a copyright symbol in HTML?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: By using &copy; or &#169; in an HTML file, you can insert a copyright symbol.
            </h3 >
            <h3 variant="h3" color="initial" sx={{ textAlign: 'center', marginY: 7 }}>
                CSS
            </h3 >
            <hr />
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                1. What Flex layout? Difference Flex and grid layout?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Flex Layout : A component engine that allows creating page layout using css flexbox with a set of directives available to use template.

                Flex layout:
                a.One dimensional.
                b.Handle row or column at a time.

                Grid Layout:
                a.Two-dimensional.
                b.Handle row and column both at a time.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                2.  Explain CSS position property? What are some differences between absolute position and relative position?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: CSS position property: It means the position of an element in a document.

                Absolute: First positioned parent. Absolute places an element relative to its parent’s position and changes the layout around it.

                Relative: Normal position. Relative places an element relative to its current position without changing the layout around it.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                3. What is a box model? And what are the different elements of a box model?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: A box model is used to create design and layout of web pages.

                The different elements of a box model are:
                Margin,
                Padding,
                Content,
                border.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                4. What is a Hover effect? What is the purpose of the active class ?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer:  A hover effect simply changes size, color, shape, etc when we put a mouse on some elements.

                Active class is a dynamic class that applies when an element is being activated by using.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >

                5. What are the different types of Selectors in CSS?

            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: The different types of Selectors in CSS are:
                Type,
                ID,
                Class,
                Descendant .

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                6.  What is CSS Specificity?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: CSS Specificity defines the levels of specificity of each type of CSS selector.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                7. What is a CSS Preprocessor? What are some benefits of Sass?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: A tool that has its own syntax which gets compiled later in standard CSS code.

                Some benefits of Sass are:

                You can write clean, easy and less CSS in a programming construct.
                You can write CSS quicker because of fewer codes.
                More stable, powerful, and elegant because it is an extension of CSS.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                8. What is a Pseudo element? What is pseudo-class?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Pseudo-elements: A keyword added to a selector that lets you style a specific part of the selected element.

                A pseudo-class:  A keyword added to a selector that specifies a special state of the selected element.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                9. How will you use media queries to make a website responsive?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: A. @media screen and (min-width:320px)
                B. and (max-width:480px)
                C. Insert your styles here.


            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                10. How will you make font size responsive?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: By using CSS, a viewport unit is the first way to achieve responsiveness to make the font size responsive.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                11. Compare Grouping and Nesting in CSS?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Grouping:  A technique used to reduce code redundancy and write clean, easy to follow code.

                Nesting: In nesting, a selector is specified within another selector.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                12. What are the ways in which CSS can be integrated as a website?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer : The three ways are:

                Inline,
                Embedded,
                Linked.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                13.what is RWD in css?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer:  In css RWD means responsive web design.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                14.  What is the difference between logical tags and physical tags?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: The difference between logical tags and physical tags are:

                Logical tags are useless for appearances and Physical tags are referred to as presentational markup.
                Logical tags are old and concentrate on the content. Physical tags are newer versions, on the other hand.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                15. Define z-index?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer:A CSS property that defines the order of overlapping Html elements. Elements with a higher index will be placed on top of elements with a lower index.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                16. What is CSS opacity?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: CSS opacity is the property that elaborates on the transparency of an element
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                17. What are the advantages of Embedded Style Sheets?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: The advantages of Embedded Style Sheets are:
                Create classes for use on multiple tag types in the document,
                Can use selector and grouping methods to apply styles in complex situations,
                No extra download is required to import the information.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                18. Tell us about CSS float property.
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: By using CSS float property u can change css position an image to the right or left as needed, including text wrapping around it. All properties of elements used before it remain unchanged.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                19.What is meant by a universal selector?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: A universal selector is a selector which can match the name of any element type, rather than selecting the elements of a specific category.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                20. What are the benefits of CSS Sprites?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: The benefits of CSS Sprites are:
                A. Loading multiple images is not an issue.
                B. Blinking is not seen.
                C. Advanced downloading of assets does not take place until needed.

            </h3 >
            <h3 variant="h3" color="initial" sx={{ textAlign: 'center', marginY: 7 }}>
                JavaScript
            </h3 >
            <hr />
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                1. How does JavaScript work?
                or What is the JavaScript Event Loop?
                Or Though JavaScript is single-threaded, how does it handle concurrent work?
                Or Is JavaScript Single-threaded or multi-threaded?
                Or Is JavaScript Synchronous or asynchronous?

            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: A client-side scripting language. JavaScript creates dynamic activity on our app. By clicking the button we control functions. Most of the web browsers support javascript. By using javascript we can run code at client-side inside web-browsers. JavaScript event loop is the secret behind JavaScript’s asynchronous programming. JavaScript executes all operations on a single thread, but using a few smart data structures. JavaScript  is Single-threaded. Javascript is asynchronous.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                2. How does JavaScript code is executed in Browser?
            </h3>
            <h3 variant="subtitle1" color="initial">
                Answer: After completing the current line then moves the next line cause JavaScript JavaScript is asynchronous. JavaScript Executes serially because JavaScript  is a single-threaded language. Execution Context is the role in the execution of JavaScript code
            </h3 >

            <h3 sx={{ fontSize: 34, color: 'red' }} >
                3.What are the differences between “==” and “===” ?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: ”==” is used to compare values
                “===”  is used to compare both values and types.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                4. What is a callback function?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: A function that is passed as an argument inside of another function is called a callback.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                5. When will you return something from a function?  Or How will you return more than one value from a function?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: We can return more than one value from a function by using the method called ‘call by address’
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                6. Tell me about bind, call and apply.
                Or How many arguments does call apply bind take?

            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Bind creates a function. Call and apply call a function. Call method pass arguments individually and apply method as an argument array.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                7. What is a Closure in JavaScript? How does it work?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: The combination of a function bundled together (enclosed) with references to its lexical environment surrounding state.

                Closures are created every time a function is created, at function creation time. To use a closure, define a function inside another function and expose it. The inner function will have access to the variables in the outer function scope, even after the outer function has returned.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                8. What does the “this” keyword indicate in JavaScript?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Represents the instance of the current object in the method of the class.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >

                9. What is Event bubbling in js? Or How does event delegate work in JS?

            </h3 >
            <h3 variant="subtitle1" color="initial">

                Answer:A method where JavaScript allows DOM elements to be nested inside each other. In such a case, if the handler of the child is clicked, the handler of the parent will also work as if it were clicked too.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                10.Explain hoisting in JavaScript.
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Hoisting is a function or a variable can be used before code execution.  It gives us an advantage that no matter where functions and variables are declared, they are moved to the top of their scope regardless of whether their scope is global or local
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                11. What is a recursive function?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: A recursive function can be defined as a routine that calls itself directly or indirectly.
                Using the recursive algorithm, certain problems can be solved quite easily.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                12. Difference between undefined and null.
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: A. Undefined means a variable has been declared but not defined yet and null is an assigned value. It means nothing.

                B. Undefined is of type undefined and null is an object.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                13. What are the different data types in JavaScript?
                Or Primitive data type and non-primitive data type

            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Data types in JavaScript are :  Number, String, Boolean, Symbol, Object, undefined, null.

                Primitive data types : Byte, short, int, long, float, double, boolean and char.

                Non-primitive data types : String, Arrays and Classes.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                14.  What is DOM?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: The Document Object Model is a programming API for HTML and XML documents.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                15.  Is JavaScript a static type or a dynamic type?

                Or How will you know the type of a JavaScript variable?

            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: JavaScript is a dynamically typed language.

                Using the type of operator we can know the type of a JavaScript variable.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                16.  What is the unshift method in JavaScript?


            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Just like the push method, which works at the beginning of the array. This method is used to prepend one or more elements to the beginning of the array.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                17. What is the difference between an alert box and a confirmation box?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: An alert box displays only the ok button but a confirmation box displays  ok and cancel both two buttons.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                18. . What is a prompt box?


            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: A prompt box is a box that allows the user to enter input by providing a text box. A label and box will be provided to enter the text or number.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                19. What is the difference between Attributes and Property?



            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Attributes:  provide more details on an element like id, type, value etc.
                Property:  is the value assigned to the property like type=”text”, value=’Name’ etc.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                20. What is let keyword in JavaScript?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: In addition to creating declarations for variables at the function level, ES6 lets you declare variables to belong to individual blocks (pairs of ), using the let keyword.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                21. What is the difference between for in and for of loop in JavaScript?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: For in loop means iterates over all enumerable property keys of an object.
                For of loop means  iterates over the values of an iterable object.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                22.  What are all the types of Pop up boxes available in JavaScript?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Alert , confirm and prompt.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                23. What is the difference between ViewState and SessionState?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: ViewState is specific to a page in a session.
                SessionState is specific to user-specific data that can be accessed across all web application pages.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                24. What are all the looping structures in JavaScript?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: For, while, do-while loops.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                25. What is break and continue statements?

            </h3 >
            <h3 variant="subtitle1" color="initial">

                Answer: Break statement means exit from the current loop.
                Continue statement continues with the next statement of the loop.
            </h3 >
            <h3 variant="h3" color="initial" sx={{ textAlign: 'center', marginY: 7 }}>
                ES6
            </h3 >
            <hr />
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                1. Tell me about Es6
                Or what ES6 features did you use?

            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Es6 means ECMAScript sixth version programming language. It defines the standard for the JavaScript implementation.ES6 brought significant changes to the JavaScript language. It adds many new features such as modules, iterators, class, arrow functions, for loop, promises, and many more enhancements to make JavaScript programming easier and more fun.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                2. What are the differences between var, let, and const?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: The differences between var, let, and const are:

                A.Var is global scoped functions but let and const are block scoped.
                B. Var and let can be declared without being initialized, const must be initialized during declaration.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                3. Why will you use default parameters?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: If there is no value then we can initialize named parameters with default values.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                4. How does the Spread operator work?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: The spread operator is a new tool in JavaScript ES6 which is iterable and expands it into individual elements. The spread operator is commonly used to make shallow copies of JS objects. Using this tool, we can handle common operations in a more concise and elegant way.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                5. Difference between class and object.
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Difference between class and object are:

                Classes have logical existence and objects have physical existence.
                Class declares only once but objects declare several times.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >

                6. What is a Prototype chain?
                Or how does inheritance work in JavaScript?

            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer:  In JavaScript every object has a private property which holds a link to another object called its prototype. That prototype object has a prototype of its own, and so on until an object is reached with null as its prototype. By definition, null has no prototype, and acts as the final link in this prototype chain.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                7. Explain Call by value vs call by reference.
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Call by value: A copy of the value is passed into the function.  Created at the different memory locations.
                call by reference: An address of value is passed into the function. Created at the same memory location.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                8. What is scope in JavaScript?
                Or Explain JavaScript scope, Block scope, and global scope?

            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Scope determines the visibility or accessibility of a variable or other resource in the area of your code.

                Block scope:  A variable when declared inside the if or switch conditions or inside for or while loops, are accessible within that particular condition or loop.

                Global scope: The variable can be accessed from any part of the code

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                9. What is a Higher-order Function?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer:  A function that takes a function as an argument or returns a function as output
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                10. What is API? Difference between Get vs post?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: APi means data connection from one software product to another one.

                Get is used to request data from a specified resource.

                Post is used to send data to a server to create or update a resource

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                11.Difference between local storage and Session storage.
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: The localStorage is an object that helps to store data with no expiration date. In localStorage, data will not be deleted after closing the browser.

                In session storage, stores data only for one session and data is deleted after closing the browser.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                12.  What are cookies? And why will you use it?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Cookies are files that hold information about you, your web browser and your behavior on the internet. A file that stored our pc or device which can be used by websites or web apps to tailor your online experience.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                13. What is object-oriented programming?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Object-oriented programming  is a style of programming characterized by the identification of classes of objects closely linked with the methods (functions) with which they are associated. It also includes ideas of inheritance of attributes and methods.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                14. Difference between Array vs LinkedList.
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Array : Index based data structure where each element is associated with an index. Uses more memory.

                LinkedList: A  data structure which contains a sequence of the elements where each element is linked to its next element.Uses less memory.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                15. How will you debug a JavaScript application?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer:  Writing code we can make many mistakes, logical and syntax errors. Using the debugging process we can solve our problems. Process are:
                Using console.log,
                Using debugger,
                Setting breakpoints.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                16. What do you mean by Babel?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Babel is a tool which is mainly used to convert edge JavaScript into plain old ES5 JavaScript that can run in any browser.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                17. Define promises in ES6?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: In ES6, promises are A way to handle asynchronous programming.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                18. Why should we use ES6 classes?



            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer : We should use ES6 classes because they are simpler and less error-prone.
                It's much easier to set up inheritance hierarchies using the new syntax than with the old.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                19. What is WeakMap in JavaScript?


            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: WeakMap stores each element as a key-value pair where keys are weakly referenced. The keys are objects and the values are arbitrary values.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                20. What is Set in JavaScript?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Sets are a new object type included in ES6 that allow the creation of collections of unique values.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                21. What are all the data types JavaScript supports?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Number, Boolean, String, null, undefined, Symbol and Object
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                22. What is the difference between Set and WeakSet in ES6?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Set: Contain all types of values. Use .size to find the number of elements. .forEach() is available for iteration.

                WeakSet : Contain only objects. Use .length to find the number of elements. .forEach() is not available for iteration.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                23. What is the Rest parameter in JavaScript?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Rest parameter in JavaScript allows a function to accept an indefinite number of arguments as an array.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                24. How do you empty an array?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Array. Length = 0;
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                25. Define negative infinity?

            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: It is a constant in JavaScript which is used to represent a lowest available value, no other number is lesser than this value. It can be generated using a self-made function or by an arithmetic operation.
            </h3 >
            <h3 variant="h3" color="initial" sx={{ textAlign: 'center', marginY: 7 }}>
                REACT
            </h3 >
            <hr />
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                1.  What is reactjs? Tell us about advantages and disadvantages of using react js.
                Or Why will you select ReactJS?
                Or there are so many different javascript frameworks. Why will you use ReactJS for your application?

            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: React is a JavaScript open source library created for building fast and interactive user interfaces for web and mobile applications. React js is a single page application. It is an open source, front-end and component-based library which handles web and mobile apps. Jordan Walke created React who was working at Facebook.

                Advantages: Easy to use and learn, Easy to understand, Performance enhancement, Support of handy tools, Scope for testing the code, Seo friendly.

                Disadvantages: High performance, Lack of document, Makes it difficult for developer, To make full application need other language.

                There are many different javascript frameworks but reactjs is a more friendly and fast application. We can handle web and mobile apps using reactjs for that reason we will select reactjs.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                2. What is JSX? How does it work?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: JSX is a syntax extension which describes React object tree. It is just like an XML-like extension to ECMAScript.

                A JSX compiler will translate any JSX into regular javascript before the file gets to the web browser. A React element which is produced by JSX and it is simply an object and its representation of a DOM node.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                3. What is Virtual dom? What are the differences between virtual and real dom?
                Or what is the diff algorithm? How does it work?

            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Virtual dom is representing a UI which is a copy of the original DOM kept in the memory and synced with the real DOM by libraries such as ReactDOM. This process is called Reconciliation.

                Virtual Dom: Best for mobile-first application. Representation of a dom object.

                Real Dom : Representation of a web page. Represent the document as nodes and objects.

                Diff algorithm : A programming tool and using this tool we can differentiate between two or more files. To find a heuristic to change anything from a state to another is the main work of a diff algorithm.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                4. Differences between props and state?
                Or How will you pass data from parent to child
                Or Can you change props?
                Or is Props readonly.

            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Props : Props are read-only and  immutable. Pass data from one component to other components as an argument. Can be accessed by the child component.

                State :  State changes can be asynchronous and mutable. State holds information about the components. Can’t be accessed by child components

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                5. What is the purpose of useState? When and why will you use it?
                Or Manage state.

            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: A function is a built in hook that can be imported from the react package.It allows us to track state in a function component.

                It is a special function that passes a function as an argument if the initial state has to be computed and the value returned by the function will be used as the initial state.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                6. What is prop drilling?
                Or What is the best way to pass data 4-5 layers down?
                Or What is a context API? How does it work?

            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Prop drilling is a  process where we pass a props with another component with the help of all the components that come between them.

                The Context API is a React structure that enables you to exchange unique details and assists in solving prop-drilling from all levels of your application.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                7. Difference between useEffect and useState?
                Or why do we need to inject dependency for useEffect

            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: UseState: It allows our functional components which used to be stateless become stateful.

                UseEffect: It allows our functional components to have lifecycle methods.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                8. What other hooks have you used other than useState and useEffect.
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer:  We used react useContext also. It makes it easy to pass data without manually passing props down the tree.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                9. Tell us about React Component lifecycle.
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: React component lifecycle can be describe into four parts:
                Initialization,
                Mounting,
                Updating,
                Unmounting.

            </h3 >

            <h3 sx={{ fontSize: 34, color: 'red' }} >
                10.  What is the purpose of a custom hook? How will you create a custom hook? Give us an example.
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Custom hook is a Javascript function. Normally we create custom hooks for reuse code from one function to another function. We use it between multiple useState and useEffect calls.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >

                11.  What is the most challenging task you have accomplished using react?

            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: One of the most challenging tasks was managing state and renders using React hooks. There is lots of confusion using useEffect.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                12.  What is Redux and uses?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Redux is a predictable way to help you write JavaScript apps which are based on the Flux design pattern.

                Redux allows react components to read data from a Redux store and dispatch actions to the store to update data. Mostly used as a state management tool with react.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                13.  Do you know about React native?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: React native is a popular JavaScript-based mobile app framework. React native is a mobile application for iOS and Android. Using react native we can develop easily for both android and iOS.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                14.  What is a Higher order component? Give us an example.
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Higher order component is an advanced technique in React for reusing component logic. It is a function that takes a component and returns a new component. Redux's connect and Relay's createContainer is one example of a Higher order component
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                15.  How would you optimize a react js application
                Or How would you prevent unnecessary component re-render in reactjs?

            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: We can optimize a react js application by using some technique. Techniques are:

                Use react,
                Using immutable data structures,
                Avoid using index as key for map,
                Function components and react,
                Avoid line function definition in the render function

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                16. How are events different in React?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer : Different are:
                Handlers are named using camelCase more than lowercase. JSX you pass a function as the event handler, rather than a string.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                17. What is the difference between Context Api and Redux??
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer:  Context Api: For small applications where state changes are minimal. It re-renders on each update the state and all  components.

                Redux : For large applications where high-frequency state updates. It re-renders only the updated components.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                18. What are the main features of Redux Form?
            </h3 >
            <h3 variant="subtitle1" color="initial">

                Answer : The main features of Redux Form are:
                Field-level validation.
                Submit validation.
                Filed Arrays.
                Asynchronous blur and change validation.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                19. When to Use Refs?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: We use refs in some cases. Cases are:
                Managing focus, text selection,
                Triggering imperative animations,
                direct access to the DOM element.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                20. Why are fragments better than container divs?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Fragments are  better than container divs because :

                Fragments are a bit faster and use less memory by not creating an extra DOM node. This only has a real benefit on very large and deep trees.
                Some CSS mechanisms like Flexbox and CSS Grid have special parent-child relationships, and adding divs in the middle makes it hard to keep the desired layout.
                The DOM Inspector is less cluttered.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                21. Differentiate between stateful and stateless components.
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Differentiate between stateful and stateless components are:

                Stateful components: Manages the state in class-based with state or functional with useState. Have authority to change state. Data keeps changing.

                Stateless components: No initial state management in it. Do not have authority to change state. Data remains the same.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                22. List down the components of Redux.
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Action,
                Reducer ,
                Store,
                View.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                23. What do you know about controlled and uncontrolled components?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Controlled components : Do not maintain their own state. Data controlled by the parent components. Allow validation control.

                Uncontrolled components: Maintain their own state. Data controlled by the DOM. doesn’t allow validation control.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                24.Which component is used to catch exceptions?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer : Throwing Exceptions from Components is used to catch exceptions.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                25. How many ways can we style the React Component?



            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer : Four ways are:
                Inline styling,
                Css stylesheet,
                Css module,
                Styled components.
            </h3 >
            <h3 variant="h3" color="initial" sx={{ textAlign: 'center', marginY: 7 }}>
                MONGO DB
            </h3 >
            <hr />
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                1. What is Nodejs? Difference between Nodejs and javaScript
                Or is Node js blocking or non-blocking?

            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Nodejs is a popular tool for any project with an open-source, cross-platform JavaScript runtime environment. Nodejs library runs web applications outside the client’s browser. Nodejs used to create server-side web applications.

                Difference between Nodejs and javaScript are :
                Nodejs : Nodejs is a Javascript runtime environment. Mostly used on the server-side. Written in C,C++ and Javascript. Express js is a nodeJs module.
                JavaScript : A programming language that is used for writing scripts on the website. Used on the client-side. Written in C++.  Frameworks are RamdaJs, TypeJs.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                2. Why did you use Node and Mongo with your React project?
                .
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: I use node on my react project to handle website maintenance and multiple requests. I also use mongodb on my react project to store a collection of data
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                3. What are the differences between sql and nosql database?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: The differences between sql and nosql database are:
                Sql : Database fixed or static. Databases are best suited for complex queries. Vertically scalable. Follows ACID property.
                NoSql : Dynamic schema. Databases are not so good for complex queries. Horizontally scalable. Follow CAP.

            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                4. What have you done with mongodb?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer: Recently I have done some projects using mongodb. It is an open source noSql document database where I have already stored a lot of data collection.
            </h3 >
            <h3 sx={{ fontSize: 34, color: 'red' }} >
                5. Have you worked on website hosting?
            </h3 >
            <h3 variant="subtitle1" color="initial">
                Answer:Web hosting means connecting your project with the internet. I use the firebase hosting app and heroku app for hosting websites.
            </h3 >








        </Container >
    )
}