import React, { useEffect, useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import figma1 from "../../../../src/Figma (1).jpg";
import figma from "../../../../src/Figma.jpg";
import figma2 from "../../../../src/Figma (2).png";
import { Link } from "react-router-dom";
import "@splidejs/react-splide/css";
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";
import "@splidejs/react-splide/css/core";
import MainDatabase from "../../../database/data.json";
import Toast from "react-bootstrap/Toast";
import "./Home.css";
import { Card } from "react-bootstrap";

const Home = () => {
  const [project, setProject] = useState([]);
  useEffect(() => {
    setProject(MainDatabase);
  }, []);
  const [result, showResult] = useState(false);
  const [show, setShow] = useState(false);
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_j2rwoqm",
        "template_4aecrur",
        form.current,
        "ILK1HRmca019H5qva"
      )
      .then(
        (result) => {
          console.log(result.text);
          showResult(true);
          setShow(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div>
      {/*-header-and-banner-section*/}
      <div className="header-and-banner-con w-100 float-left position-relative">
        <div className="header-and-banner-inner-con">
          <header className="main-header fixed-header">
            {/*navbar-start*/}
            <div className="container pl-0 pr-0">
              <div className="header-con">
                <nav className="navbar navbar-expand-lg navbar-light p-0">
                  <button
                    className="navbar-toggler p-0 collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    style={{ boxShadow: "none" }}
                  >
                    <span
                      className="navbar-toggler-icon"
                      style={{ display: "block", height: "3px" }}
                    />
                    <span
                      className="navbar-toggler-icon"
                      style={{ display: "block", height: "7px" }}
                    />
                    <span
                      className="navbar-toggler-icon"
                      style={{ display: "block", height: "7px" }}
                    />
                  </button>
                  <div
                    className="collapse navbar-collapse justify-content-end"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav ml-auto">
                      <li className="nav-item active pl-0">
                        <a className="nav-link p-0 is-active" href="#home">
                          Home<span className="sr-only">(current)</span>
                        </a>
                      </li>

                      <li className="nav-item ">
                        <a className="nav-link p-0" href="#about-con">
                          About
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link p-0" href="#Portfolio">
                          Portfolio
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link p-0" href="#testimonials">
                          Testimonials
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link p-0" href="#blog">
                          Blog
                        </a>
                      </li>
                    </ul>
                    <div className="d-inline-block contact">
                      <a href="#Contact">Contact</a>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
            {/*navbar-end*/}
          </header>
          <section className="banner-main-con h-100" id="home">
            <div className="container pl-0 pr-0" style={{ height: "700px" }}>
              {/*banner-start*/}
              <div style={{}}>
                <div>
                  <div className="footer-social-icon banner-social-icon mb- ">
                    <ul className="mb-0 list-unstyled">
                      <li className>
                        <a href="https://www.facebook.com/mahamud.pino/">
                          <i className="fab fa-facebook d-flex align-items-center justify-content-center" />
                        </a>
                      </li>
                      <li className="mt-3 mb-3">
                        <a href="https://github.com/mahamud751">
                          <i className="fab fa-github d-flex align-items-center justify-content-center ml-0 mr-0 " />
                        </a>
                      </li>
                      <li className>
                        <a href="https://www.linkedin.com/in/mahamud-pino63a564183/">
                          <i className="fab fa-linkedin-in d-flex align-items-center justify-content-center" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="banner-con text-lg-left text-center mt-5 100"
                    style={{}}
                  >
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "700px",
                      }}
                    >
                      <div className="col-lg-7 col-sm-12d-flex justify-content-center flex-column  ">
                        <div className="banner-left-con wow slideInLeft">
                          <div className="banner-heading">
                            <h2>Hello, I Am</h2>
                            <ul className="dynamic-txts">
                              <li>
                                <h1>Mahamud Pino</h1>
                              </li>
                            </ul>
                            <p>Full Stack Developer</p>
                          </div>
                          <div className="banner-btn generic-btn d-inline-block">
                            <a href="#Contact">Hire Me</a>
                          </div>
                          <a href="#Portfolio" className="See-btn">
                            See My Work
                          </a>
                        </div>
                      </div>
                      <div className="col-lg-5 col-sm-12">
                        <div
                          className="banner-right-con position-relative wow slideInRight"
                          id="banner-right-con"
                        >
                          <figure className="mt-5">
                            <img
                              src="https://i.ibb.co/KWVvRkc/293219746-899863180981574-644245908910229972-n.jpg"
                              className="img-fluid"
                              alt="banner-right-img"
                              id="banner-right-img"
                              style={{ borderRadius: "50%", width: "400px" }}
                            />
                          </figure>
                          {/* <div
                        className="best-award-con d-inline-block wow bounceInUp"
                        data-wow-duration="1s"
                        data-wow-delay="1s"
                      >
                        <div className="best-award-inner-con">
                          <figure className="mb-0">
                            <img
                              src="assets/image/cup-img.png"
                              alt="cup-img"
                              className="img-fluid"
                            />
                          </figure>
                          <div className="best-award-title">
                            <p className="mb-0">
                              Best Design
                              <br />
                              Award.
                            </p>
                          </div>
                        </div>
                      </div> */}
                          {/* <div
                        className="best-award-con d-inline-block happy-con wow bounceInUp "
                        data-wow-duration="1s"
                        data-wow-delay="1s"
                      >
                        <div className="best-award-inner-con text-center">
                          <figure>
                            <img
                              src="assets/image/admin-icon.png"
                              alt="admin-icon"
                              className="img-fluid"
                            />
                          </figure>
                          <div className="best-award-title d-inline-block ml-0">
                            <p className="mb-0 d-inline-block count">4</p>
                            <p className="mb-0 d-inline-block">k+</p>
                            <span className="d-block">
                              Happy
                              <br />
                              Customers
                            </span>
                          </div>
                        </div>
                      </div> */}
                          <div className="cursor" />
                          <div className="cursor2" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*banner-end*/}
            </div>
          </section>
        </div>
      </div>
      {/*-header-and-banner-section*/}
      {/* service section */}
      {/* <section
        className="w-100 float-left service-con padding-top padding-bottom position-relative"
        style={{ backgroundColor: "#1f1b3b" }}
        id="service-con"
      >
        <div className="container">
          <div className="service-inner-con position-relative">
            <div className="generic-title text-center">
              <h6>My Expertise</h6>
              <h2 className="mb-0">
                Provide Wide Range of
                <br />
                Digital Services
              </h2>
            </div>
            <div
              className="service-box wow fadeInUp"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div
                    className="service-box-item"
                    style={{ transform: "rotate(0deg)" }}
                  >
                    <figure className="mb-0">
                      <img
                        src="assets/image/service-icon1.png"
                        alt="service-icon"
                        className="img-fluid"
                      />
                    </figure>
                    <div className="service-box-item-content">
                      <h4>Ui/Ux Design</h4>
                      <p>
                        Dolor repellendus temporibus autem quibusdam officiis
                        debitis rerum neces aibus minima veniam.
                      </p>
                
                        Read More
                    
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="service-box-item">
                    <figure className="mb-0">
                      <img
                        src="assets/image/service-icon2.png"
                        alt="service-icon"
                        className="img-fluid"
                      />
                    </figure>
                    <div className="service-box-item-content">
                      <h4>Web Design</h4>
                      <p>
                        Dolor repellendus temporibus autem quibusdam officiis
                        debitis rerum neces aibus minima veniam.
                      </p>
                    
                        Read More
                    
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-0">
                <div className="col-lg-6 col-md-6">
                  <div
                    className="service-box-item"
                    style={{ transform: "rotate(0deg)" }}
                  >
                    <figure className="mb-0">
                      <img
                        src="assets/image/service-icon3.png"
                        alt="service-icon"
                        className="img-fluid"
                      />
                    </figure>
                    <div className="service-box-item-content">
                      <h4>Web Development</h4>
                      <p>
                        Dolor repellendus temporibus autem quibusdam officiis
                        debitis rerum neces aibus minima veniam.
                      </p>
                     
                        Read More
                    
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="service-box-item mb-0">
                    <figure className="mb-0">
                      <img
                        src="assets/image/service-icon4.png"
                        alt="service-icon"
                        className="img-fluid"
                      />
                    </figure>
                    <div className="service-box-item-content">
                      <h4>App Development</h4>
                      <p>
                        Dolor repellendus temporibus autem quibusdam officiis
                        debitis rerum neces aibus minima veniam.
                      </p>
                    
                        Read More
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* service section */}
      {/* skill section */}
      <section
        className="w-100 float-left skill-con padding-top padding-bottom position-relative"
        id="about-con"
      >
        <figure className="mb-0 myskils">
          <img src="assets/image/myskills-left-img.png" alt="fetg" />
        </figure>
        <div className="container">
          <div className="skill-inner-con position-relative">
            <div className="row">
              <div className="col-lg-6 order-lg-0 order-2">
                <div className="skill-left-con text-center wow slideInLeft">
                  <div className="row service-skill-sttaf-con">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="skill-left-item-con">
                        <div className="circle-wrap firstPercentage">
                          <div className="circle">
                            <div className="mask full">
                              <div className="fill" />
                            </div>
                            <div className="mask half">
                              <div className="fill" />
                            </div>
                            <div className="inside-circle">
                              <div className="service-skill-sttaf-item-con">
                                <div className="service-skill-sttaf-item-title service-skill-sttaf-item1-con d-flex align-items-center justify-content-center">
                                  <h4 className="d-table-cell align-middle mb-0 text-center count">
                                    75
                                  </h4>
                                  <span className="static-txt2">%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="service-skill-sttaf-item-heading">
                          <p className="mb-0">Graphic Design</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="skill-left-item-con">
                        <div className="circle-wrap secondPercentage">
                          <div className="circle">
                            <div className="mask full">
                              <div className="fill" />
                            </div>
                            <div className="mask half">
                              <div className="fill" />
                            </div>
                            <div className="inside-circle">
                              <div className="service-skill-sttaf-item-con">
                                <div className="service-skill-sttaf-item-title service-skill-sttaf-item2-con text-center d-flex align-items-center justify-content-center">
                                  <h4 className=" mb-0 text-center count">
                                    95
                                  </h4>
                                  <span className=" static-txt2">%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="service-skill-sttaf-item-heading">
                          <p className="mb-0">Web Designing</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row service-skill-sttaf-con">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="skill-left-item-con">
                        <div className="circle-wrap thirdPercentage">
                          <div className="circle">
                            <div className="mask full">
                              <div className="fill" />
                            </div>
                            <div className="mask half">
                              <div className="fill" />
                            </div>
                            <div className="inside-circle">
                              <div className="service-skill-sttaf-item-con">
                                <div className="service-skill-sttaf-item-title service-skill-sttaf-item1-con d-flex align-items-center justify-content-center">
                                  <h4 className="d-table-cell align-middle mb-0 text-center count">
                                    85
                                  </h4>
                                  <span className="static-txt2">%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="service-skill-sttaf-item-heading">
                          <p className="mb-0">Branding Design</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 ">
                      <div className="skill-left-item-con">
                        <div className="circle-wrap fourPercentage">
                          <div className="circle">
                            <div className="mask full">
                              <div className="fill" />
                            </div>
                            <div className="mask half">
                              <div className="fill" />
                            </div>
                            <div className="inside-circle">
                              <div className="service-skill-sttaf-item-con">
                                <div className="service-skill-sttaf-item-title service-skill-sttaf-item2-con text-center d-flex align-items-center justify-content-center">
                                  <h4 className=" mb-0 text-center count">
                                    80
                                  </h4>
                                  <span className=" static-txt2">%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="service-skill-sttaf-item-heading">
                          <p className="mb-0">Web Development</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex align-items-center">
                <div className="skill-right-con wow slideInRight">
                  <h6>My Skills</h6>

                  <p>
                    Hi! My name is Pino. I am a Web Developer, and I'm very
                    passionate and dedicated to my work. With 2 years experience
                    as a professional Web developer, I have acquired the skills
                    and knowledge necessary to make your project a success.
                  </p>
                  <p></p>
                  <div className="generic-btn download-bnt">
                    <a
                      href="https://drive.google.com/file/d/1Id4UPA0Iz9ZVm4tztjx-MJkt6IuiSlhz/view?usp=sharing"
                      id="downloadImg"
                      onClick="downloadImage()"
                    >
                      Download CV
                    </a>
                  </div>

                  {/*container*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* skill section */}

      {/* portfolio section */}
      <section
        className="w-100 float-left portfolio-con padding-top h-100"
        id="Portfolio"
      >
        <div className="container" style={{ marginBottom: "120px" }}>
          <div className="generic-title text-center">
            <h2 className="text-white"> Check My Portfolio</h2>
            <h4 className="mb-0 text-white">Latest Projects</h4>
          </div>

          <>
            <Splide
              options={{
                // type: "loop",
                drag: "free",
                focus: "center",
                gap: "2rem",
                perPage: 2,
                height: "22rem",
                breakpoints: {
                  1200: { arrows: false, perPage: 2 },
                  800: { perPage: 2 },
                  640: { perPage: 1 },
                },
                autoScroll: {
                  speed: 2,
                },
              }}
              extensions={{ AutoScroll }}
            >
              {project.map((product, index) => (
                <SplideSlide key={index}>
                  <div
                    className="portfolio-img-con position-relative w-100 float-left wow fadeInUp"
                    style={{
                      visibility: "visible",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="filterDiv cars position-relative show w-100">
                      <div className="portfolio-img position-relative">
                        <figure>
                          <img
                            src={product.img}
                            alt="portfolio-img1"
                            className="img-fluid home_img"
                            style={{ width: "100%", height: "300px" }}
                          />
                        </figure>
                      </div>

                      <div className="portfolio-img-content ">
                        <div className="portfolio-img-title">
                          <Link to={`/singleProducts/${product._id}`}>
                            <button className="button-sm">
                              {" "}
                              Project Details
                            </button>
                          </Link>
                          <button className="button-sm mt-2 ms-0 ms-lg-3">
                            {" "}
                            <a href={product.live}>Project Live</a>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </SplideSlide>
              ))}
            </Splide>
          </>
        </div>
        <div className="px-lg-5" style={{ marginBottom: "120px" }}>
          <div className="generic-title text-center">
            <h4 className="text-white">Static Website</h4>
          </div>

          <>
            <Splide
              options={{
                // type: "loop",
                drag: "free",
                focus: "center",
                gap: "2rem",
                perPage: 3,
                height: "38rem",
                breakpoints: {
                  1200: { arrows: false, perPage: 3 },
                  800: { perPage: 2 },
                  640: { perPage: 1 },
                },
              }}
              // extensions={{ AutoScroll }}
            >
              <SplideSlide>
                <Card style={{ background: "unset", border: "none" }}>
                  <div className="scroll_view">
                    <a href="https://coldrate.com">
                      <img
                        className="img-fluid rounded-3"
                        src="https://i.ibb.co/GMJ5FV1/screencapture-coldrate-2023-01-02-15-30-09.png"
                        alt="First slide"
                        style={{ height: 600 }}
                      />
                    </a>
                  </div>
                </Card>
              </SplideSlide>

              <SplideSlide>
                <Card style={{ background: "unset", border: "none" }}>
                  <div className="scroll_view">
                    <a href="https://supper-trade.netlify.app/">
                      <img
                        className="img-fluid rounded-3"
                        src="https://i.ibb.co/JQ90fxr/screencapture-supper-trade-netlify-app-2023-01-02-15-48-54.png"
                        alt="First slide"
                        style={{ height: 600 }}
                      />
                    </a>
                  </div>
                </Card>
              </SplideSlide>
              <SplideSlide>
                <Card style={{ background: "unset", border: "none" }}>
                  <div className="scroll_view">
                    <a href="https://static-office-website.vercel.app">
                      <img
                        className="img-fluid rounded-3"
                        src="https://i.ibb.co/gPtvVX6/screencapture-static-office-website-vercel-app-2023-01-02-16-09-13.png"
                        alt="First slide"
                        style={{ height: 600 }}
                      />
                    </a>
                  </div>
                </Card>
              </SplideSlide>
              <SplideSlide>
                <Card style={{ background: "unset", border: "none" }}>
                  <div className="scroll_view">
                    <a href="https://medical-project1.vercel.app">
                      <img
                        className="img-fluid rounded-3"
                        src="https://i.ibb.co/PzTdcPp/screencapture-medical-project1-vercel-app-2023-01-02-16-13-42.png"
                        alt="First slide"
                        style={{ height: 600 }}
                      />
                    </a>
                  </div>
                </Card>
              </SplideSlide>
              <SplideSlide>
                <Card style={{ background: "unset", border: "none" }}>
                  <div className="scroll_view">
                    <a href="https://thirsty-dijkstra-3e0a66.netlify.app/">
                      <img
                        className="img-fluid rounded-3"
                        src="https://i.ibb.co/sgmb7hn/screencapture-thirsty-dijkstra-3e0a66-netlify-app-2023-01-02-16-21-08.png"
                        alt="First slide"
                        style={{ height: 600 }}
                      />
                    </a>
                  </div>
                </Card>
              </SplideSlide>
            </Splide>
          </>
        </div>
        <div className="px-lg-5" style={{ marginBottom: "120px" }}>
          <div className="generic-title text-center">
            <h4 className="text-white">UI DESIGN</h4>
          </div>

          <>
            <Splide
              options={{
                // type: "loop",
                drag: "free",
                focus: "center",
                gap: "2rem",
                perPage: 3,
                height: "38rem",
                breakpoints: {
                  1200: { arrows: false, perPage: 3 },
                  800: { perPage: 2 },
                  640: { perPage: 1 },
                },
              }}
              // extensions={{ AutoScroll }}
            >
              <SplideSlide>
                <Card style={{ background: "unset", border: "none" }}>
                  <div className="scroll_view">
                    <a href="https://www.behance.net/gallery/152378263/grocery-store">
                      <img
                        className="img-fluid rounded-3"
                        src="https://i.ibb.co/pPxdFBZ/grocery-store.png"
                        alt="First slide"
                        style={{ height: 600 }}
                      />
                    </a>
                  </div>
                </Card>
              </SplideSlide>

              <SplideSlide>
                <Card style={{ background: "unset", border: "none" }}>
                  <div className="scroll_view">
                    <a href="https://www.behance.net/gallery/152493699/Admin-Dashboard">
                      <img
                        className="img-fluid rounded-3"
                        src="https://i.ibb.co/mXdMGJB/Frame-1.png"
                        alt="First slide"
                        style={{ height: 600 }}
                      />
                    </a>
                  </div>
                </Card>
              </SplideSlide>
              <SplideSlide>
                <Card style={{ background: "unset", border: "none" }}>
                  <div className="scroll_view">
                    <a href="https://www.behance.net/gallery/152779773/Restaurant">
                      <img
                        className="img-fluid rounded-3"
                        src="https://i.ibb.co/QpXZKLd/Mac-Book-Pro-16-1.png"
                        alt="First slide"
                        style={{ height: 600 }}
                      />
                    </a>
                  </div>
                </Card>
              </SplideSlide>
              <SplideSlide>
                <Card style={{ background: "unset", border: "none" }}>
                  <div className="scroll_view">
                    <a href="https://www.behance.net/gallery/164586027/Ecommerce-Website">
                      <img
                        className="img-fluid rounded-3"
                        src="https://i.ibb.co/KG3CV5x/Untitled-design-4.png"
                        alt="First slide"
                        style={{ height: 600 }}
                      />
                    </a>
                  </div>
                </Card>
              </SplideSlide>
              <SplideSlide>
                <Card style={{ background: "unset", border: "none" }}>
                  <div className="scroll_view">
                    <a href="https://www.behance.net/gallery/164595827/Pharmacy-Website">
                      <img
                        className="img-fluid rounded-3"
                        src="https://i.ibb.co/hmr8DN1/Frame-515-5.png"
                        alt="First slide"
                        style={{ height: 600 }}
                      />
                    </a>
                  </div>
                </Card>
              </SplideSlide>
              <SplideSlide>
                <Card style={{ background: "unset", border: "none" }}>
                  <div className="scroll_view">
                    <a href="https://www.behance.net/gallery/152847319/Travel-Landing-Page">
                      <img
                        className="img-fluid rounded-3"
                        src="https://i.ibb.co/mtQYTgt/Mac-Book-Pro-16-2.png"
                        alt="First slide"
                        style={{ height: 600 }}
                      />
                    </a>
                  </div>
                </Card>
              </SplideSlide>
              <SplideSlide>
                <Card style={{ background: "unset", border: "none" }}>
                  <div className="scroll_view">
                    <a href="https://www.behance.net/gallery/150773423/Food-Delivery-website">
                      <img
                        className="img-fluid rounded-3"
                        src="https://i.ibb.co/sjJCtvS/Mac-Book-Pro-16-1-2.png"
                        alt="First slide"
                        style={{ height: 600 }}
                      />
                    </a>
                  </div>
                </Card>
              </SplideSlide>
              <SplideSlide>
                <Card style={{ background: "unset", border: "none" }}>
                  <div className="scroll_view">
                    <a href="https://www.behance.net/gallery/152493699/Admin-Dashboard">
                      <img
                        className="img-fluid rounded-3"
                        src="https://i.ibb.co/svx72J1/Frame-1-1.png"
                        alt="First slide"
                        style={{ height: 600 }}
                      />
                    </a>
                  </div>
                </Card>
              </SplideSlide>
            </Splide>
          </>
        </div>
        {/** */}
      </section>
      {/* portfolio section */}
      {/* portfolio section */}
      <section className="w-100 float-left portfolio-body-con position-relative">
        <div className="container">
          <div className="portfolio-img-con position-relative w-100 float-left wow fadeInUp">
            <div className="filterDiv cars position-relative">
              <div className="portfolio-img position-relative">
                <figure>
                  <img
                    src="assets/image/portfolio-img1.png"
                    alt="portfolio-img1"
                    className="img-fluid"
                  />
                </figure>
              </div>

              <div className="portfolio-img-content text-left">
                <div className="portfolio-img-title d-inline-block">
                  <h4>Application UI Design</h4>
                  <p>Dolar repellendus temporibus...</p>
                </div>

                <i className="fas fa-arrow-right d-flex align-items-center justify-content-center" />
              </div>
            </div>
            <div className="filterDiv colors fruits position-relative">
              <div className="portfolio-img position-relative">
                <figure>
                  <img
                    src="assets/image/portfolio-img2.png"
                    alt="portfolio-img1"
                    className="img-fluid"
                  />
                </figure>
              </div>

              <div className="portfolio-img-content text-left">
                <div className="portfolio-img-title d-inline-block">
                  <h4>Furni furniture UI Design</h4>
                  <p>Dolar repellendus temporibus...</p>
                </div>

                <i className="fas fa-arrow-right d-flex align-items-center justify-content-center" />
              </div>
            </div>
            <div className="filterDiv cars position-relative">
              <div className="portfolio-img position-relative">
                <figure>
                  <img
                    src="assets/image/portfolio-img3.png"
                    alt="portfolio-img1"
                    className="img-fluid"
                  />
                </figure>
              </div>

              <div className="portfolio-img-content text-left">
                <div className="portfolio-img-title d-inline-block">
                  <h4>Mobile UI Design</h4>
                  <p>Dolar repellendus temporibus...</p>
                </div>

                <i className="fas fa-arrow-right d-flex align-items-center justify-content-center" />
              </div>
            </div>
            <div className="filterDiv colors position-relative">
              <div className="portfolio-img position-relative">
                <figure>
                  <img
                    src="assets/image/portfolio-img4.png"
                    alt="portfolio-img1"
                    className="img-fluid"
                  />
                </figure>
              </div>

              <div className="portfolio-img-content text-left">
                <div className="portfolio-img-title d-inline-block">
                  <h4>Businesscard UI Design</h4>
                  <p>Dolar repellendus temporibus...</p>
                </div>

                <i className="fas fa-arrow-right d-flex align-items-center justify-content-center" />
              </div>
            </div>
            <div className="filterDiv cars animals position-relative">
              <div className="portfolio-img position-relative">
                <figure>
                  <img
                    src="assets/image/portfolio-img5.png"
                    alt="portfolio-img1"
                    className="img-fluid"
                  />
                </figure>
              </div>

              <div className="portfolio-img-content text-left">
                <div className="portfolio-img-title d-inline-block">
                  <h4>Real estate UI Design</h4>
                  <p>Dolar repellendus temporibus...</p>
                </div>

                <i className="fas fa-arrow-right d-flex align-items-center justify-content-center" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* portfolio section */}
      {/* tastimonials section */}
      <section
        className="w-100 float-left padding-top padding-bottom tastimonials-con position-relative text-lg-left text-center"
        id="testimonials"
        style={{ backgroundColor: "#1f1b3b" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="tastimonials-left-con wow slideInLeft">
                <figure className="mb-0">
                  <img
                    src="assets/image/tastimonials-img.png"
                    alt="tastimonials-img"
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
            <div className="col-lg-7">
              <div
                id="carouselExampleControls"
                className="carousel slide wow slideInRight"
                data-ride="carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="testimonials-content">
                      <h6>Testimonials</h6>
                      <h2>Happy Clients Feedback</h2>
                      <figure className="mb-0">
                        <img
                          src="assets/image/comma-icon.png"
                          alt="comma-icon"
                          className="img-fluid"
                        />
                      </figure>
                      <div className="testimonials-inner-content">
                        <p>
                          Quisruam est, qui dolorem ipsum quia dolor sit amet,
                          consecteaur aeci velit, sed quia non numquam eius modi
                          tempora incidunt ut lao magnam aliquam quaerat
                          voluptatem reprehenderit in voluptate cillum dolore eu
                          fugiat nulla pariatur maxime...
                        </p>
                        <span className="d-block auther-name">
                          Kevin Andrew
                        </span>
                        <span className="d-block">CEO of the company</span>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="testimonials-content">
                      <h6>Testimonials</h6>
                      <h2>Happy Clients Feedback</h2>
                      <figure className="mb-0">
                        <img
                          src="assets/image/comma-icon.png"
                          alt="comma-icon"
                          className="img-fluid"
                        />
                      </figure>
                      <div className="testimonials-inner-content">
                        <p>
                          Quisruam est, qui dolorem ipsum quia dolor sit amet,
                          consecteaur aeci velit, sed quia non numquam eius modi
                          tempora incidunt ut lao magnam aliquam quaerat
                          voluptatem reprehenderit in voluptate cillum dolore eu
                          fugiat nulla pariatur maxime...
                        </p>
                        <span className="d-block auther-name">
                          Kevin Andrew
                        </span>
                        <span className="d-block">CEO of the company</span>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="testimonials-content">
                      <h6>Testimonials</h6>
                      <h2>Happy Clients Feedback</h2>
                      <figure className="mb-0">
                        <img
                          src="assets/image/comma-icon.png"
                          alt="comma-icon"
                          className="img-fluid"
                        />
                      </figure>
                      <div className="testimonials-inner-content">
                        <p>
                          Quisruam est, qui dolorem ipsum quia dolor sit amet,
                          consecteaur aeci velit, sed quia non numquam eius modi
                          tempora incidunt ut lao magnam aliquam quaerat
                          voluptatem reprehenderit in voluptate cillum dolore eu
                          fugiat nulla pariatur maxime...
                        </p>
                        <span className="d-block auther-name">
                          Kevin Andrew
                        </span>
                        <span className="d-block">CEO of the company</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/*   <a
                  className="carousel-control-prev"
                  href="#carouselExampleControls"
                  role="button"
                  data-slide="prev"
                        >
                  <i className="fas fa-arrow-left d-flex align-items-center justify-content-center" />
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  href="#carouselExampleControls"
                  role="button"
                  data-slide="next"
                >
                  <i className="fas fa-arrow-right d-flex align-items-center justify-content-center" />
                  <span className="sr-only">Next</span>
                </a>*/}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* tastimonials section */}
      {/* blog section */}
      <section
        className="w-100 float-left blog-con padding-top padding-bottom position-relative"
        id="blog"
      >
        <div className="container">
          <div className="blog-inner-con position-relative">
            <div className="generic-title text-center">
              <h6>Latest News</h6>
              <h2 className="mb-0">Blog &amp; Articles</h2>
            </div>
            <div className="blog-box wow fadeInUp">
              <div className="row">
                <div className="col-lg-4">
                  <div className="blog-box-item mb-0">
                    <div className="blog-img">
                      <a href="https://dev.to/mahamud751/data-structure-and-algorithms-javascript-39ec">
                        <figure className="mb-0">
                          <img
                            src={figma2}
                            alt="blog-img"
                            className="img-fluid"
                          />
                        </figure>
                      </a>
                    </div>
                    <div className="blog-content">
                      <div className="blog-auteher-title">
                        <span>By Pino</span>
                        <span className="float-lg-right">July 10, 2022</span>
                      </div>
                      <a href="https://dev.to/mahamud751/data-structure-and-algorithms-javascript-39ec">
                        <h4>Data Structure and Algorithms</h4>
                      </a>
                      <p>
                        Hey ! Now time to explore more learn Data Structure and
                        Algorithms JavaScrip.
                      </p>
                      <a href="https://dev.to/mahamud751/data-structure-and-algorithms-javascript-39ec">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="blog-box-item">
                    <div className="blog-img">
                      <a href="https://dev.to/mahamud751/e-commerce-mobile-app-figma-ui-design-187k">
                        <figure className="mb-0">
                          <img
                            src={figma}
                            alt="blog-img"
                            className="img-fluid"
                          />
                        </figure>
                      </a>
                    </div>
                    <div className="blog-content">
                      <div className="blog-auteher-title">
                        <span>By Pino</span>
                        <span className="float-lg-right">July 9, 2022</span>
                      </div>
                      <a href="https://dev.to/mahamud751/e-commerce-mobile-app-figma-ui-design-187k">
                        <h4>Andriod App Figma Design</h4>
                      </a>
                      <p>
                        Design a cool figma design for an android mobile
                        application
                      </p>
                      <a href="https://dev.to/mahamud751/e-commerce-mobile-app-figma-ui-design-187k">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="blog-box-item">
                    <div className="blog-img">
                      <Link to={"/blog"}>
                        <figure className="mb-0">
                          <img
                            src={figma1}
                            alt="blog-img"
                            className="img-fluid"
                          />
                        </figure>
                      </Link>
                    </div>
                    <div className="blog-content">
                      <div className="blog-auteher-title">
                        <span>By Pino</span>
                        <span className="float-lg-right">July 8, 2022</span>
                      </div>
                      <Link to={"/blog"}>
                        <h4>Interview Questions</h4>
                      </Link>
                      <p>Here you find more than 100 question and answers.</p>
                      <Link to={"/blog"}>Read More</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* blog section */}
      {/* form section */}
      <section
        className="w-100 float-left form-main-con padding-top padding-bottom"
        id="Contact"
        style={{ backgroundColor: "#1f1b3b" }}
      >
        <div className="container">
          <div className="form-main-inner-con position-relative">
            <div className="generic-title text-center">
              <h6>Get in Touch</h6>
              <h2 className="mb-0">
                Any Questions? Feel Free
                <br />
                to Contact
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-4 order-lg-0 order-2">
                <div className="contact-information position-relative wow slideInLeft">
                  <ul className="list-unstyled">
                    <li>
                      <figure className="mb-0 d-flex align-items-center justify-content-center">
                        <img
                          src="assets/image/location-icon.png"
                          alt="location-icon"
                          className="img-fluid"
                        />
                      </figure>
                      <div className="contact-information-content">
                        <h5>Address:</h5>
                        <p className="mb-0">Rampura, Dhaka</p>
                      </div>
                    </li>
                    <li>
                      <figure className="mb-0 d-flex align-items-center justify-content-center">
                        <img
                          src="assets/image/message-icon.png"
                          alt="message-icon"
                          className="img-fluid"
                        />
                      </figure>
                      <div className="contact-information-content">
                        <h5>Email:</h5>

                        <p className="mb-0">mahamudpino24678@gmail.com</p>
                      </div>
                    </li>
                    <li className="mb-0">
                      <figure className="mb-0 d-flex align-items-center justify-content-center">
                        <img
                          src="assets/image/phone-icon.png"
                          alt="phone-icon"
                          className="img-fluid"
                        />
                      </figure>
                      <div className="contact-information-content">
                        <h5>Phone:</h5>
                        <p className="mb-0">+88 01789999751</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8">
                <div id="form_result"></div>
                <form
                  id="contactpage"
                  ref={form}
                  onSubmit={sendEmail}
                  className="contact-form wow slideInRight text-lg-left text-center"
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          placeholder="Name"
                          name="fullName"
                          id="name"
                          autoComplete="off"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group mb-0">
                        <input
                          type="email"
                          id="emailHelp"
                          name="email"
                          placeholder="Email"
                          autoComplete="off"
                          required
                        />
                        <small className="form-text text-muted" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group mb-0">
                        <input
                          type="tel"
                          placeholder="Phone"
                          name="phone"
                          id="phone"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          name="subject"
                          placeholder="Subject"
                          id="subject"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className=" form-group mb-0">
                        <textarea
                          placeholder="Message"
                          rows={3}
                          name="message"
                          id="comments"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                  </div>

                  <input
                    className="form-bg"
                    type="submit"
                    value="Send"
                    style={{
                      border: "none",
                      padding: "19px 64px 18px",
                      background: "var(--accent)",
                      color: "var(--white-color)",
                      fontSize: "20px",
                      lineHeight: "22px",
                      borderRadius: "36px",
                      width: "180px",
                    }}
                  />

                  {result ? (
                    <>
                      <Toast
                        onClose={() => setShow(false)}
                        show={show}
                        delay={1000}
                        autohide
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Toast.Body>Thank you for your review</Toast.Body>
                      </Toast>
                    </>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* form section */}
      {/* weight footer section */}
      <div className="w-100 float-left weight-footer-con position-relative">
        <div className="container">
          <div className="weight-footer-content text-center wow fadeInUp">
            {/* <figure className>
              <img
                src="assets/image/footer-logo.png"
                alt="footer-logo"
                className="img-fluid"
              />
            </figure> */}
            <div className="footer-navbar">
              <ul className="list-unstyled">
                <li className="d-inline-block border-left-0 pl-0">
                  <a href="#home">Home</a>
                </li>
                <li className="d-inline-block">
                  <a href="#about-con">About</a>
                </li>

                <li className="d-inline-block">
                  <a href="#Portfolio">Portfolio</a>
                </li>
                <li className="d-inline-block">
                  <a href="#blog">Blog</a>
                </li>
                <li className="d-inline-block pr-0">
                  <a href="#Contact">Contact</a>
                </li>
              </ul>
            </div>
            <div className="footer-social-icon">
              <ul className="mb-0">
                <li className="d-inline-block">
                  <a href="https://www.facebook.com/mahamud.pino/">
                    <i className="fab fa-facebook d-flex align-items-center justify-content-center" />
                  </a>
                </li>
                <li className="d-inline-block">
                  <a href="https://github.com/mahamud751">
                    <i className="fab fa-github d-flex align-items-center justify-content-center" />
                  </a>
                </li>
                <li className="d-inline-block">
                  <a href="https://www.linkedin.com/in/mahamud-pino63a564183/">
                    <i className="fab fa-linkedin-in d-flex align-items-center justify-content-center" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="copy-right-content text-center">
            <p className="mb-0">Copyright 2022 @PINO | All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
