import { Card, Container } from "react-bootstrap";
import { useParams } from "react-router";
import data from "../../../database/data.json";
import { Link } from "react-router-dom";
import "./SingleProducts.scss";

const SingleProducts = () => {
  let { id } = useParams();

  const project = data.find((pd) => pd._id === id);

  return (
    <div>
      <header className="main-header fixed-header">
        <div className="container pl-0 pr-0">
          <div className="header-con">
            <nav className="navbar navbar-expand-lg navbar-light p-0">
              <button
                className="navbar-toggler p-0 collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                style={{ boxShadow: "none" }}
              >
                <span
                  className="navbar-toggler-icon"
                  style={{ display: "block", height: "3px" }}
                />
                <span
                  className="navbar-toggler-icon"
                  style={{ display: "block", height: "7px" }}
                />
                <span
                  className="navbar-toggler-icon"
                  style={{ display: "block", height: "7px" }}
                />
              </button>
              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item active pl-0">
                    <Link to={"/#home"}>
                      Home<span className="sr-only">(current)</span>
                    </Link>
                  </li>

                  <li className="nav-item ">
                    <Link to={"/#about-con"}>About</Link>
                  </li>

                  <li className="nav-item">
                    <Link to={"/#Portfolio"}>Portfolio</Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0" href="#testimonials">
                      Testimonials
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link to={"/#blog"}>Blog</Link>
                  </li>
                </ul>
                <div className="d-inline-block contact">
                  <Link to={"/#Contact"}>Contact</Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
        {/*navbar-end*/}
      </header>
      <div style={{ backgroundColor: "#212529" }}>
        <Container>
          <div
            className="mt-5"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <h1 className="fw-bold text-white fs-1">{project.name}</h1>
          </div>

          <div className="my-5 text-white">
            <h1 className="my-5">Features</h1>
            <p>
              <i class="fa-solid fa-check"></i>
              {project.description1}
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              {project.description2}
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              {project.description3}
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              {project.description4}
            </p>
          </div>
          <div className="my-5 text-white">
            <h1>Technologies</h1>
            <h3 className="my-5">{project.technology}</h3>
          </div>
          <div className="row">
            <div className="col-md-12 mt-5">
              <Card
                className="border-2 rounded-5 p-4"
                style={{ background: "rgb(237 255 244)" }}
              >
                <div className="scroll_view">
                  <img
                    className="img-fluid"
                    src={project.img1}
                    alt="First slide"
                  />
                </div>
              </Card>
            </div>
            <div className="col-md-12 mt-5">
              <Card
                className="border-2 rounded-5 p-5"
                style={{ background: "rgb(237 255 244)" }}
              >
                <div className="scroll_view">
                  <img
                    className="img-fluid"
                    src={project.img2}
                    alt="First slide"
                  />
                </div>
              </Card>
            </div>
            <div className="col-md-12 mt-5">
              <Card
                className="border-2 rounded-5 p-5"
                style={{ background: "rgb(237 255 244)" }}
              >
                <div className="scroll_view">
                  <img
                    className="img-fluid"
                    src={project.img3}
                    alt="First slide"
                  />
                </div>
              </Card>
            </div>
          </div>

          {/* <Col className="mx-auto p-5 mt-5">
            <Card
              className="border-2 rounded-5  p-5"
              style={{ background: "rgb(237 255 244)" }}
            >
              <div>
                <Splide
                  options={{
                    type: "loop",
                    drag: "free",
                    focus: "center",
                    height: "442px",
                    gap: "2rem",
                    perPage: 1,

                    breakpoints: {
                      1200: { arrows: false, perPage: 1 },
                      800: { perPage: 1 },
                      640: { perPage: 1 },
                    },
                    autoScroll: {
                      speed: 2,
                    },
                  }}
                  extensions={{ AutoScroll }}
                >
                  <SplideSlide>
                    <div className="col-md-offset-4 content">
                      <div className="screen_sm">
                        <img
                          className="img-fluid"
                          src={project.img1}
                          alt="First slide"
                        />
                      </div>
                    </div>
                  </SplideSlide>
                  <SplideSlide>
                    <div className="col-md-offset-4 content">
                      <div className="screen_sm">
                        <img
                          className="img-fluid"
                          src={project.img2}
                          alt="First slide"
                        />
                      </div>
                    </div>
                  </SplideSlide>
                  <SplideSlide>
                    <div className="col-md-offset-4 content">
                      <div className="screen_sm">
                        <img
                          className="img-fluid"
                          src={project.img3}
                          alt="First slide"
                        />
                      </div>
                    </div>
                  </SplideSlide>
                </Splide>
              </div>
            </Card>
          </Col> */}
        </Container>
      </div>
      <div className="w-100 float-left weight-footer-con position-relative">
        <div className="container">
          <div className="weight-footer-content text-center wow fadeInUp">
            <div className="footer-navbar">
              <ul className="list-unstyled">
                <li className="d-inline-block border-left-0 pl-0">
                  <a href="#home">Home</a>
                </li>
                <li className="d-inline-block">
                  <a href="#about-con">About</a>
                </li>

                <li className="d-inline-block">
                  <a href="#Portfolio">Portfolio</a>
                </li>
                <li className="d-inline-block">
                  <a href="#blog">Blog</a>
                </li>
                <li className="d-inline-block pr-0">
                  <a href="#Contact">Contact</a>
                </li>
              </ul>
            </div>
            <div className="footer-social-icon">
              <ul className="mb-0">
                <li className="d-inline-block">
                  <a href="https://www.facebook.com/mahamud.pino/">
                    <i className="fab fa-facebook d-flex align-items-center justify-content-center" />
                  </a>
                </li>
                <li className="d-inline-block">
                  <a href="https://github.com/mahamud751">
                    <i className="fab fa-github d-flex align-items-center justify-content-center" />
                  </a>
                </li>
                <li className="d-inline-block">
                  <a href="https://www.linkedin.com/in/mahamud-pino63a564183/">
                    <i className="fab fa-linkedin-in d-flex align-items-center justify-content-center" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="copy-right-content text-center">
            <p className="mb-1">Copyright 2022 @PINO | All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProducts;
