import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Blog from "./components/Pages/Blog/Blog";
import Home from "./components/Pages/Home/Home";
import SingleProducts from "./components/Pages/SingleProducts/SingleProducts";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home></Home>}></Route>
          <Route path="/home" element={<Home></Home>}></Route>
          <Route path="/blog" element={<Blog></Blog>}></Route>

          <Route
            path="/singleProducts/:id"
            element={<SingleProducts></SingleProducts>}
          ></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
